import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Helmet } from "react-helmet"

import { Layout } from "../components"
import { Banner, Link, Button, ButtonStyle } from "@igloonet-web/shared-ui"

import IconWebhosting from "../icons/webpage.svg"
import IconSprava from "../icons/chat-love.svg"
import IconVirtualy from "../icons/rocket.svg"

const Hosting = (): React.ReactNode => (
  <Layout>
    <Helmet>
      <title>Hosting – webhosting, virtuální servery, správa | igloonet</title>
      <meta
        name="description"
        content="Pomůžeme s výběrem domény a založením vhodného hostingu
            přesně podle vašich potřeb. Nabízíme klasický webhosting pro weby a
            eshopy i virtuální servery pro rozsáhlejší webové projekty."
      />
    </Helmet>
    <Banner>
      <h1>
        <Link to="https://igloonet.hosting">igloonet.hosting</Link>
      </h1>
      <p>Webhosting, který roste s vámi.</p>
    </Banner>
    <Container className="text-center mt-5">
      <Row className="mb-5">
        <Col md={4}>
          <a
            href="https://igloonet.hosting/webhosting/"
            title="Webhosting"
            className="text-decoration-none"
          >
            <IconWebhosting width={96} height={96} />
            <div className="p-4">
              <h2 className="d-inline">Webhosting</h2>
            </div>
          </a>
          <p className="px-lg-5">
            Nejnovější <strong>PHP</strong>, přístup přes <strong>SSH</strong>,
            editace cronů a spousta dalších vychytávek. Umíme taky{" "}
            <strong>Python</strong>, <strong>Ruby</strong> a{" "}
            <strong>node.js</strong>.
          </p>
        </Col>
        <Col md={4}>
          <a
            href="https://igloonet.hosting/virtualni-servery/"
            title="Virtuální servery"
            className="text-decoration-none"
          >
            <IconVirtualy width={96} height={96} />
            <div className="p-4">
              <h2 className="d-inline">Virtuální servery</h2>
            </div>
          </a>
          <p className="px-lg-5">
            Potřebujete speciální nastavení či větší bezpečnost? Pořiďte si
            virtual v našem privátním cloudu.
          </p>
        </Col>
        <Col md={4}>
          <a
            href="https://igloonet.hosting/sprava-serveru/"
            title="Správa serverů"
            className="text-decoration-none"
          >
            <IconSprava width={96} height={96} />
            <div className="p-4">
              <h2 className="d-inline">Správa serverů</h2>
            </div>
          </a>
          <p className="px-lg-5">
            Na Linuxu umíme rozjet téměř vše. Hlavně ale umíme zařídit, aby to
            zůstalo běžet i v budoucnu.
          </p>
        </Col>
      </Row>
    </Container>

    <Container className="text-center mt-5">
      <p>
        <Button to="https://igloonet.hosting" style={ButtonStyle.Primary}>
          Více na igloonet.hosting
        </Button>
      </p>
    </Container>
  </Layout>
)

export default Hosting
